@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:ital@1&display=swap');

.fancy-box{
    width: 100%;
    height: 20px;
    /* position: absolute; */
    height: 40%;
    opacity: 90%;
}
.fancy-box img{
    rotate: 15deg;
    width: 120%;
    /* object-fit: cover; */
    position: absolute;
    z-index: 1;
    padding: 100px 0;
    margin: 0 -200px;
}

.greet-container{
    width: 100%;
}

.greet{
    height: 70vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0 10%;
    color: white;
}
.greet-text{
    width: 50%;
}
.greet-text h1{
    font-family: 'Pacifico';
    font-size: 40px;
    font-weight: 1000;
    padding-bottom: 20px;
}
.greet-text p{
    font-size: 20px;
    font-family: monospace;
    font-weight: 600;
    line-height: 1.6;
    text-align: justify;
    padding: 10px;
    cursor: context-menu;
}
.greet-img{
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.greet-img img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
}


@media screen and (max-width: 1000px) {
    .greet{
        flex-direction: column-reverse;
        height: 100%;
        gap: 20px;
    }
    .greet-text{
        padding-bottom: 20px;
        width: 100%;
    }
    .greet-text h1{
        font-family: 'Pacifico';
        font-size: 30px;
        font-weight: 1000;
        padding-bottom: 20px;
    }
    .greet-text p{
        width: 100%;
        font-family: monospace;
        line-height: 1.5;
        text-align: justify;
        /* padding: 10px; */
        cursor: context-menu;
    }
    .greet-img{
        padding-top: 20px;
        width: 350px;
        height: 350px;
    }
    .greet-img img{
        /* position: inherit; */
        width: 300px;
        height: 300px;
        object-fit: cover;
        border-radius: 50%;
        border: 5px solid white;
    }
}