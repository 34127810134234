* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-style: normal;
}

:root {
	--mainColor: #1D2228;
	--mainColorLight: #5767aa;
	--secondaryColor: #f5a623;
	--textColor: #eee;
    --navbar-text-color: hsl(0, 0%, 85%);
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
}

nav {
	font-family: "Poppins";
	width: 100%;
	display: flex;
	align-items: center;
}

.nav-options {
	display: flex;
	align-items: center;
	margin-left: auto;
}

nav a {
	font-size: 1.7rem;
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
	transition: 0.3s;
	font-weight: 700;
	font-style: normal;
}

nav a:hover {
	color: var(--secondaryColor);
}

.Title {
	font-family: 'Pacifico', cursive;
	font-size: 2rem;
	color: var(--textColor);
	text-align: left; /* Pastikan teks berada di kiri */
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

    nav {
        margin-right: 0;
    }

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}
