body{
    margin: 0;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.Background-img {
    background-image: url('../Components/page/images/Farewell.png');
    z-index: -4;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%; /* Lebar penuh */
    min-height: 100vh; /* Tinggi minimum adalah 100% viewport */
    display: flex; /* Jika ada konten, tambahkan fleksibilitas */
    align-items: center; /* Konten vertikal berada di tengah */
    justify-content: center; /* Konten horizontal berada di tengah */
}
.Background{
    width: 100%; /* Lebar penuh */
    min-height: 100vh; /* Tinggi minimum adalah 100% viewport */
    display: flex; /* Jika ada konten, tambahkan fleksibilitas */
    align-items: center; /* Konten vertikal berada di tengah */
    justify-content: center; /* Konten horizontal berada di tengah */
}
.date-input-container {
    width: 55%;
    height: 100px;
    text-align: center;
    margin: 20px auto; /* Untuk memusatkan secara horizontal */
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50px;

    display: flex; /* Aktifkan flexbox */
    flex-direction: row; 
    justify-content: center; /* Pusatkan secara horizontal */
    align-items: center; /* Pusatkan secara vertikal */
    gap: 20px; /* Jarak antar elemen */
}

label {
    margin-left: 15px; /* Memberikan jarak antara label dan input */
    color: rgb(255, 255, 255);
    
}

input::placeholder {
    color: rgb(255, 255, 255); /* Ganti dengan warna yang diinginkan */
    font-style: italic; /* Opsional: untuk menambah gaya */
    font-family: 'Poppins';
    border: none;
}

#date-input {
    background-color: rgba(0, 0, 0, 0.7);
    color: rgb(255, 255, 255);
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
}

button {
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50px;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Poppins';
}
@media screen and (max-width: 1500px) {
    .date-input-container {
        width: 90%;
        height: 200px;
        flex-direction: column;
        gap: 10px;
    }
    #date-input {
        margin-right: 0;
    }
    
}